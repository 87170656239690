<ng-container *ngrxLet="showSpinner$ as showSpinner">
  <div *ngIf="showSpinner" class="content_loader">
    <div class="spinner-container">
      <mat-spinner [color]="'accent'" [diameter]="150"></mat-spinner>
      <div class="spinner-container-image-icon">
        <pbb-svg-icon [icon]="'konek-logo'"></pbb-svg-icon>
      </div>
    </div>
    <span> {{ 'COMMON.LOADING' | translate }}</span>
  </div>
</ng-container>

<pbb-feedback-button
  role="region"
  *ngIf="showFeedbackButton"
  (close$)="showFeedbackButton = false"
></pbb-feedback-button>
<div class="content">
  <router-outlet></router-outlet>
</div>
<footer *ngIf="!hideFooter" role="contentinfo" [attr.aria-label]="'ARIA_LABEL.FOOTER' | translate">
  <pbb-footer id="footer"></pbb-footer>
</footer>
