import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService, Languages } from '@core/services/language.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LangToggleComponent } from '../lang-toggle/lang-toggle.component';
import { MatIconModule } from '@angular/material/icon';
import { PbbSvgIconComponent } from '../pbb-svg-icon/pbb-svg-icon.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'pbb-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, PbbSvgIconComponent, MatIconModule, LangToggleComponent, NgFor, TranslateModule],
})
export class FooterComponent {
  Languages = Languages;
  footerLinks: Array<any> = [
    { url: 'FOOTER.LINKS.TNC.URL', link: 'FOOTER.LINKS.TNC.LINK' },
    { url: 'FOOTER.LINKS.HELP.URL', link: 'FOOTER.LINKS.HELP.LINK' },
    { url: 'FOOTER.LINKS.FEEDBACK.URL', link: 'FOOTER.LINKS.FEEDBACK.LINK' },
    { url: 'FOOTER.LINKS.MERCHANTS.URL', link: 'FOOTER.LINKS.MERCHANTS.LINK' },
    { url: 'FOOTER.LINKS.PRIVACY_POLICY.URL', link: 'FOOTER.LINKS.PRIVACY_POLICY.LINK' },
  ];
  isPilot: boolean = false;
  islanding: boolean = false;

  constructor(
    public translateService: TranslateService,
    public languageService: LanguageService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {
    this.router.events.subscribe(() => {
      if (this.isPilot !== window.location.href.includes('pilot')) {
        this.isPilot = window.location.href.includes('pilot');
        this.ref.detectChanges();
      }
    });
  }
}
